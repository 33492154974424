import React from "react"
import { Helmet } from "react-helmet"
import { graphql, PageProps } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"

function addDraftBanner(x: boolean) {
  if (x) {
    return <Banner text="This is a draft!" />
  }
  return null
}

export default function Template(props: PageProps<Queries.BlogPostByPathQuery>) {
  const post = props.data.markdownRemark // data.markdownRemark holds our post data
  if (process.env.NODE_ENV === "development") {
    console.log("Frontmatter: " + JSON.stringify(post?.frontmatter ?? "invalid"))
  }
  var keywordsParsed: string[]
  if (post?.frontmatter?.keywords) {
    keywordsParsed = post.frontmatter.keywords.split(",").map((x) => x.trim())
  } else {
    keywordsParsed = []
  }
  return (
    <>
      <SEO title={post?.frontmatter?.title} keywords={keywordsParsed} />

      <Layout
        preContentBanners={addDraftBanner(post?.frontmatter?.draft ?? false)}
        renderName={false}
        allowExtraWide={false}
      >
        <div className="center mw8">
          <Helmet title={`${post?.frontmatter?.title}`} />
          <article className="pa3 w-70-l center">
            <h1 className="f3 f2-m f1-l">{post?.frontmatter?.title}</h1>
            <h4 className="">{post?.frontmatter?.date}</h4>
            <div
              className="lh-copy allow-fullwidth-image"
              dangerouslySetInnerHTML={{ __html: post?.html ?? "missing" }}
            />
          </article>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        keywords
        bigImage
        smallImage
        draft
      }
    }
  }
`
