import React from "react"
import "tachyons"

const Banner = (props: { text: string }) => (
  <div className="center mw6 mw8-ns hidden ba bw2 b--yellow mt3 w-90">
    <h1 className="f4 near-black mv0 pv2 ph3 bg-yellow b ">Warning!</h1>
    <div className="pa3 bt b--yellow">
      <p className="f6 f5-ns lh-copy measure mv0">{props.text}</p>
    </div>
  </div>
)

export default Banner
